<template>
  <div class="main-content">
    <div class="section">
      <div class="page-404 text-center">
        <div class="large-404">404</div>
        <h2>Ничего не найдено</h2>
        <p>Извините, но страница которую вы ищете не существует.</p>
        <div class="search-form-2 block-center">
          <div class="src-form">
            <div class="src-input">
              <input placeholder="Search" type="email">
            </div>
            <div class="src-btn">
              <button><i class="li_search"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  async asyncData (context) {
    await context.store.dispatch('contactsPage');
  },
  metaInfo () {
    return this.$seo(this.$store.state.about ? this.$store.state.about.nameSchool : '');
  }
};
</script>
